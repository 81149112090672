export function override(baseClasses: string, newClasses?: string): string {
  if (typeof newClasses !== 'string') return baseClasses
  if (typeof baseClasses !== 'string') return newClasses
  const uniqueClasses = new Set<string>(baseClasses.split(' ').filter(Boolean))
  newClasses
    .split(' ')
    .map((s) => s.trim())
    .forEach((c) => uniqueClasses.add(c))
  return [...uniqueClasses].join(' ')
}
